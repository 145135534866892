<template>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <form class="m-0 mt-" ref="formFilter" @submit.prevent="getData()">
          <div class="form-row">
            <div class="align-items-center justify-content-left col-md-3">
              <div class="form-group mb-0">
                <label for="">Tag Tema</label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagSubjects"
                  @search="fetchTagSubjects"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group mb-0">
                <label for="">Período de cadastro</label>
                <flat-pickr
                  v-model="filters.rangeDate"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group mb-0">
                <label for="">Título</label>
                <input
                  v-model="filters.title"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-1">
              <label for="" style="visibility: hidden">Buscar</label>
              <button
                type="submit"
                :disabled="submitedFilter"
                class="btn btn-primary btn-block"
              >
                <div v-if="submitedFilter">
                  <b-spinner label="Loading..." small />
                </div>
                <div v-else>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer cursor"
                    size="16"
                  />
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="3"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <template #cell(created_at)="{ item }">
          <span v-if="!item.updated_at">
            {{ item.created_at | datePT(true) }}
          </span>
          <span v-else>
            {{ item.updated_at | datePT(true) }}
          </span>
        </template>

        <template #cell(tags)="data">
          <span
            v-if="data.item.tags"
            class="badge badge-light-secondary"
            v-b-popover.hover.top="`${data.item.tags.list}`"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Tags cadastradas"
          >
            <i class="bi bi-tags"></i>
            {{ data.item.tags.total }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              v-if="$can('Disciplina - Excluir', 'Pedagógico')"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              v-if="$can('Disciplina - Editar', 'Pedagógico')"
              @click="modalEdit(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
      />

      <b-modal
        id="modal-create-discipline"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
        no-close-on-backdrop
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-12 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.title"
                  :class="{ 'is-invalid': $v.item.title.$error }"
                />
              </div>
            </div>

            <div class="col-md-12 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tag Tema
              </label>
              <div class="form-group">
                <v-select
                  multiple
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.tags.subject"
                  placeholder="Digite o título da tag"
                  :options="optionsTagSubjects"
                  @search="fetchTagSubjects"
                  :class="{ 'is-invalid': $v.item.tags.subject.$error }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-discipline')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  VBPopover,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  data() {
    return {
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "title",
          label: "Título",
        },
        {
          key: "tags",
          label: "Tags",
          thStyle: "width: 80px",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          sortable: false,
          class: "text-center",
          thStyle: "width: 210px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        title: "",
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
        ead: 0,
      },
      optionsTagSubjects: [],
      filters: {
        tag_subject: [],
        rangeDate: null,
        title: "",
      },
      submitedFilter: false,
    };
  },
  validations: {
    item: {
      title: {
        required,
      },
      tags: {
        subject: {
          required,
        },
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-discipline", () => {
      this.item = {
        title: "",
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
        ead: 0,
      };

      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar";
      this.$bvModal.show("modal-create-discipline");
    });

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-discipline");
    this.$root.$off("paginate:update");
  },
  methods: {
    async fetchTagSubjects(term) {
      this.optionsTagSubjects = [];
      if (term.length > 2) {
        this.optionsTagSubjects = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";

      this.$store.dispatch("Discipline/show", uuid).then((data) => {
        this.item = data;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-discipline");
      });
    },
    submitCreate() {
      this.$v.item.$touch();
      this.submited = true;
      const clearForm = {
        title: "",
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
        ead: 0,
      };

      if (!this.$v.item.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Discipline/create", this.item)
            .then(() => {
              this.$v.item.$reset();
              this.item = clearForm;
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-discipline");
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Discipline/update", {
              uuid: this.uuid,
              data: this.item,
            })
            .then(() => {
              this.$v.item.$reset();
              this.item = clearForm;
              this.getData();

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-discipline");
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Discipline/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: {
            ead: 0,
            tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
            title: this.filters.title ?? "",
            rangeDate: this.filters.rangeDate ?? "",
          },
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Discipline/delete", uuid).then(() => {
            this.getData();
            this.notifyDefault("success");
          });
        }
      });
    },
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
};
</script>
